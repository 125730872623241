export const ruTranslations = {
  translation: {
    api: {
      ACCESS_DENIED: 'Пользователь авторизован, но доступ запрещен!',
      ALREADY_ACTIVATED: 'Код или пользователь уже активирован!',
      AUTH_ERROR: 'Неверный номер или код',
      AUTH_ERROR_BLOCKED: 'Аккаунт заблокирован. Пожалуйста, свяжитесь со службой поддержки',
      AUTH_ERROR_DISABLED: 'Аккаунт отключен. Пожалуйста, свяжитесь со службой поддержки',
      AUTH_ERROR_MODERATION: 'Аккаунт находится в стадии модерации',
      BAD_REQUEST: 'Некорректный запрос',
      EXPIRED_TOKEN:
        'Ваша сессия истекла или доступ к этому ресурсу запрещен. Пожалуйста, авторизуйтесь и попробуйте снова.',
      INTERNAL_ERROR: 'Ошибка сервера, пожалуйста, свяжитесь с администратором!',
      INVALID_EMAIL_FORMAT: 'Неверный формат электронной почты',
      INVALID_OTP_EXPIRED: 'Неверный код(истек срок)',
      INVALID_OTP_NOT_FOUND: 'Неверный код(код не зарегистрирован)',
      INVALID_OTP_USED: 'Неверный код(уже использован)',
      INVALID_RESET_CODE: 'Неверный код сброса',
      INVALID_TOKEN: 'Неверный токен!',
      SYSTEM_ERROR: 'Ошибка сервера, пожалуйста, свяжитесь с администратором!',
      TRIAL_TARIFF_IN_USE: 'Пробный период уже был использован',
      UNIQUE_RESOURCE_CONFLICT: 'Уникальный ресурс конфликтует',
      USER_ALREADY_EXISTS: 'Данный номер уже зарегистрирован. Войдите в систему',
      USER_NOT_FOUND: 'Данный номер не найден, зарегистрируйтесь',
      WRONG_ACCOUNT_STATUS: 'Недействительный статус аккаунта',
      apiRequestFailed: 'Запрос к интерфейсу не удался, пожалуйста, попробуйте позже!',
      apiTimeoutMessage:
        'Истекло время ожидания запроса к интерфейсу, пожалуйста, обновите страницу и повторите попытку!',
      badCredentials: 'Неверный номер или код',
      errMsg401:
        'Ваша сессия истекла или доступ к этому ресурсу запрещен. Пожалуйста, авторизуйтесь и попробуйте снова.',
      errMsg403: 'Пользователь авторизован, но доступ запрещен!',
      errMsg404: 'Ошибка сетевого запроса, ресурс не найден!',
      errMsg405: 'Ошибка сетевого запроса, метод запроса не разрешен!',
      errMsg408: 'Время ожидания сетевого запроса истекло!',
      errMsg500: 'Ошибка сервера, пожалуйста, свяжитесь с администратором!',
      errMsg501: 'Сеть не реализована!',
      errMsg502: 'Ошибка сети!',
      errMsg503: 'Сервис недоступен, сервер временно перегружен или находится на обслуживании!',
      errMsg504: 'Время ожидания сети истекло!',
      errMsg505: 'Версия http не поддерживает запрос!',
      errorMessage: 'Операция не удалась, система неполадочна!',
      errorTip: 'Подсказка об ошибке',
      networkException: 'Сетевая аномалия',
      networkExceptionMsg:
        'Пожалуйста, проверьте, что ваше сетевое подключение нормальное! Сеть находится в аномальном состоянии',
      noTokenInResponse: 'Нет токена в запросе',
      operationFailed: 'Операция не удалась',
      operationSuccess: 'Операция успешна',
      successTip: 'Подсказка об успешном выполнении',
      timeoutMessage: 'Время входа истекло, пожалуйста, выполните вход снова!',
    },
    auth: {
      accountExists: 'Уже есть аккаунт?',
      accountNotExists: 'Нет аккаунта?',
      authorization: 'Авторизация',
      codeConfirmation: 'Код подтверждения',
      codeGet: 'Получить код',
      codeResendAfter: 'Запросить код повторно можно через:',
      codeResentTimer: 'Запросить код повторно можно через: {{seconds}} секунд',
      codeSeconds: 'секунд',
      confirmLogout: 'Вы уверены, что хотите выйти?',
      logout: 'Выход',
      nameInput: 'Введите ваше имя',
      phoneInput: 'Введите номер телефона',
      sendAgain: 'Отправить повторно',
      signin: 'Вход',
      signinAction: 'Войти',
      signup: 'Регистрация',
      signupAction: 'Зарегистрироваться',
      signupToContinue: 'Пожалуйста, зарегистрируйтесь для продолжения',
    },
    categories: {
      choose: 'Выберите категорию',
      productCategory: 'Категория товаров one',
      productCategory_few: 'Категории товаров few ',
      productCategory_many: 'Категорий товаров many',
    },
    common: {
      LTD: 'ТОО',
      accepted: 'Отправлено',
      accountNumber: 'Лицевой счет',
      active: 'Активный',
      active_few: 'Активных',
      active_many: 'Активных',
      actives: 'Активные',
      addPhoto: 'Добавить фото',
      available: 'Есть',
      back: 'Назад',
      balance: 'Баланс',
      call: 'Позвонить',
      camera: 'Камера',
      cancel: 'Отмена',
      cancel_action: 'Отменить',
      category: 'Категория',
      choose: 'Выберите',
      chooseMethod: 'Выберите способ',
      chosen: 'Выбран',
      close: 'Закрыть',
      closed: 'Закрыт',
      comment: 'Комментарий',
      contact: 'Связаться',
      contactSeller: 'Связаться с продавцом',
      contactWhatsApp: 'Связаться по WhatsApp',
      continue: 'Продолжить',
      dateRequest: 'Дата запроса',
      dateSignup: 'Дата регистрации',
      declined: 'Отклонено',
      editData: 'Изменить данные',
      error: 'Ошибка',
      find: 'Найти',
      fullNameShort: 'ФИО',
      history: 'История',
      inputData: 'Введите данные',
      loading: 'Загрузка...',
      market: 'Рынок',
      missed: 'Пропущенный',
      missed_few: 'Пропущенных',
      missed_many: 'Пропущенных',
      missed_s: 'Пропущенные',
      moreDetails: 'Подробнее',
      next: 'Далее',
      no: 'Нет',
      noAccess: 'Нет доступа',
      notActive: 'Неакивный',
      offer: 'Предложение',
      ok: 'ОК',
      onMap: 'На карте',
      phoneNumber: 'Номер телефона',
      photoLibrary: 'Фото-медиатека',
      photoLibraryOpen: 'Открыть камеру/фото-медиатеку',
      photography: 'Фотография',
      placeholderFrom: `От {{from}}`,
      placeholderTo: `До {{to}}`,
      price: 'Цена',
      privacyPolicy: 'Политика конфиденциальности',
      product: 'Товар',
      productCategory: 'Категория товара',
      product_few: 'Товара',
      product_many: 'Товары',
      profilePersonal: 'Личный кабинет',
      publicOffer: 'Публичная оферта',
      refresh: 'Обновить',
      refreshPage: 'Обновить страницу',
      rejected: 'Отказано',
      request: 'Запрос',
      request_few: 'Запроса',
      request_many: 'Запросов',
      requests: 'Запросы',
      requestsActive: 'Активные запросов',
      requestsHistory: 'История запросов',
      reset: 'Сбросить',
      retail: 'Розница',
      rightsReserved: 'Все права защищены',
      save: 'Сохранить',
      sellerPremium: 'Премиум продавец',
      sent: 'Отправлено',
      settings: 'Настройки',
      shop: 'Магазин',
      showMore: 'Показать ещё',
      termsOfService: 'Условия использования',
      title: 'Наименование',
      verifiedSeller: 'Проверенный продавец',
      wholesale: 'Опт',
      yes: 'Да',
    },
    forms: {
      linkMustBeHttps: 'Ссылка должна начинаться с https://',
      maxLength: 'Максимальная длина вводимого поля должна быть {{length}} символ(-ов)',
      minLength: 'Минимальная длина вводимого поля должна быть {{length}} символ(-ов)',
      phoneError: "Телефон номера должен быть введен в формате: '+7(999)-999-99-99'",
      required: 'Обязательное поле',
      requiredCategories: 'Пожалуйста выберите категории',
      requiredMarket: 'Пожалуйста, выберите рынок',
    },
    home: {
      becomeBuyer: 'Стать покупателем',
      becomeSeller: 'Стать продавцом',
      beneficialForEveryone: 'Выгодно для всех',
      economyTime: 'Экономьте время и деньги',
      findProfitableOffers: 'Находите выгодные предложения без необходимости обходить весь рынок',
      forBuyers: 'Покупателям',
      forBuyersDescription: 'Экономьте время на поиске товаров по самой выгодной цене.',
      forSellers: 'Продавцам',
      forSellersDescription: 'Увеличение потока клиентов в точку продаж',
      helpUsers: 'Помогите посетителям рынка найти вашу точку и увеличить свои обороты',
      howItWorks: 'Как это работает',
      howItWorks1: 'Укажите в поисковой строке товар, который вы ищете на рынке',
      howItWorks2: 'Получайте предложения от продавцов на рынке',
      howItWorks3: 'Выбирайте самые выгодные предложения',
      howItWorks4: 'Быстро находите продавца, благодаря геолокации',
      inputProductName: 'Введите название товара',
      sellMore: 'Продавайте больше с GoBazar',
      weHelpSellersAndBuyers: 'Мы помогаем продавцам и посетителям рынков находить друг друга',
    },
    messages: {
      offersFound: 'По вашему запросу найдены предложения:',
      offersNoResponded: 'Пока нет запросов на которые вы откликались',
      offersNotFound: 'Предложения не найдены',
      offersWaitingSellers: 'Ожидаем ответа от продавцов',
      pageNotFound: 'Страница не найдена',
      productNotFound: 'Товар не найден',
      requestNotFound: 'Запрос не найден',
      requestOrOfferNotFound: 'Запрос или предложение не найдены',
      requestsNoCustomers: 'Пока нет запросов от покупателей',
      requestsNotCreated: 'Вы еще не создавали запросы',
      shopNoCategories: 'У вас не указаны категории!',
      shopNoCategoriesAdd:
        'Для этого нужно добавить в профиль категории товаров которые вы продаете',
      shopNoCategoriesContactAdministrator:
        'Свяжитесь с администратором, чтобы начать получать запросы от покупателей.',
      shopNotFound: 'Магазин не найден',
      shopsNotFound: 'Магазины не найдены',
    },
    moderation: {
      accountUnderReview: 'Ваш аккаунт проходит модерацию.',
      accountUnderReviewDescription:
        'Это может занять до 24 часов, но чаще всего модерация происходит в течение 10 минут. Пожалуйста обновите страницу позже',
      pageTitle: 'Модерация аккаунта',
      pleaseWait: 'Пожалуйста, подождите, пока мы проверим данные вашей учетной записи.',
    },
    offers: {
      addPhoto: 'Добавьте фотографии товара',
      canNotAddManyPhotos: 'К предложению нельзя добавить больше 3 изображений',
      chooseOfferStatus: 'Выберите статус предложений',
      fastAnswer: 'Быстрый ответ',
      inputPrice: 'Укажите цену',
      makeOffer: 'Сделать предложение',
      offerDate: 'Дата и время предложения:',
      offerDateFrom: 'Дата запроса, от',
      offerDateTo: 'Дата запроса, до',
      offerDeclined: 'Запрос отклонен',
      offerSentToCustomer: 'Предложение отправлено покупателю',
      offersBanner:
        'Заявки активны <span class="font-semibold">15 минут.</span> Отклик в первые 2 минуты повышает шанс продажи на 80%',
      offersFromSellers: 'Предложения от продавцов',
      productCategories: 'Категория товара:',
      productIsAvailable: 'Товар в наличии',
      productPhotos: 'Фотографии товара',
      recommendedSellers: 'Рекомендуемые продавцы по вашему запросу',
      similar: 'Похожие предложения:',
      trySeeSimilar: 'Вы можете посмотреть похожие предложения',
    },
    premium: {
      contactAdministrator: 'Обратитесь к администратору',
      contactAdministratorBy: 'Обратитесь к администратору по',
      contactUs: 'Связаться с нами',
      dailyLimit: 'Суточный лимит',
      dailyLimitEnded: 'Вы исчерпали суточный лимит.',
      limitUsedUp: ' Вы исчерпали суточный лимит.',
      noLimitProductCreate: 'Нет лимита на создание предложений',
      premiumBefits: 'Преимущества Premium:',
      priorityShowcase: 'Приоритетное ранжирование магазина у покупателей',
      tariffPeriod: 'Срок тарифа',
      tariffPeriodEnded: 'Срок тарифа истек',
      tariffPeriodEnding: 'Срок вашего тарифа подходит к концу',
      tariffYourPeriodEnded: 'Срок вашего тарифа истек',
      topUpAccountBalance: 'Пополните лицевой счет или обратитесь к администратору по',
      trialPeriod: 'пробный период',
      trialPeriodSuccess: 'Пробный тариф успешно подключен',
      tryFree: 'Попробовать бесплатный',
    },
    product: {
      addProduct: 'Добавить товар',
      createProduct: 'Создать товар',
      editProduct: 'Изменить товар',
      newProduct: 'Новый товар',
      priceFrom: 'Цена, от',
      priceFromTo: `От {{from}} до {{to}}`,
      priceTo: 'Цена, до',
      productDescription: 'Описание товара',
      updateProduct: 'Обновить товар',
    },
    requests: {
      addPhoto: 'Добавить фотографии',
      createNewRequest: 'Создать новый запрос',
      customerInfoBanner: 'Продавцы готовят предложения, обычно это занимает 3-5 минут.',
      findProduct: 'Найти товар',
      inputRequest: 'Введите запрос',
      priceNotEmpty: 'Поле "Цена" не может быть пустой и должна содержать только цифры',
      rejectRequest: 'Отклонить запрос',
      requestSent: 'Запрос отправлен.',
    },
    screens: {
      main: 'Главная страница',
      requestDetails: 'Детальная страница запроса',
    },
    seller: {
      about: 'О продавце',
    },
    shop: {
      about: 'О Магазине',
      accountNumberCopied: 'Номер лицевого счета скопирован в буфер обмена',
      addProfilePhoto: 'Добавьте фото профиля магазина',
      appDoesNotHaveAccessTo: 'Приложение не имеет разрешения к',
      canNotAddManyPhotos: 'Нельзя добавить больше 3 изображений',
      card: 'Карточка магазина',
      contactGoBazarText:
        'Добрый день!\nПишу с приложения продавца GoBazar.\nОбращаюсь по поводу ...',
      createCard: 'Создать страницу магазина',
      deleteAccount: 'Удалить аккаунт',
      deleteConfirmation: 'Вы действительно хотите удалить аккаунт?',
      description: 'Описание магазина',
      inputAddress: 'Введите адрес',
      inputFullNameShort: 'Введите ФИО',
      logoutConfirmation: 'Вы действительно хотите выйти из аккаунта?',
      marketsLoading: 'Загрузка рынков...',
      rowPlace: 'ряд, место',
      selectMarket: 'Выбрать рынок',
      selectPhoto: 'Выбрать фото',
      sellerFullNameShort: 'ФИО продавца',
      shopAddress: 'Адрес магазина',
      shopName: 'Название магазина',
      shopPhotos: 'Фотографии магазина',
      shopSaved: 'Магазин сохранен',
      toAddPhotosAllowSettings:
        'Чтобы иметь возможность добавлять фотографии, разрешите приложению доступ в настройках телефона.',
      wantToEditCategory:
        'Если вам нужно отредактировать категории товаров, обратитесь к администрации сервиса',
    },
    tradeTypes: {
      RETAIL: 'Розничная',
      RETAILCommerce: 'Розничная торговля',
      RETAILRequest: 'В розницу',
      WHOLESALE: 'Оптовая',
      WHOLESALECommerce: 'Оптовая торговля',
      WHOLESALERequest: 'Оптом',
      choose: 'Выберите тип торговли',
      tradeType: 'Тип торговли',
    },
    update: {
      appUpdating: 'Обновление приложения',
      downloadedRestarting: 'Обновление загружено, перезагрузка приложения',
      downloading: 'Обновление загружается',
      update: 'Обновление',
    },
  },
};
